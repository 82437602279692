import React from "react"
import Image from "../components/image"

const Info = () => {
  return (
    <section id="info" className="section info">
      <div className="section__inner">
        <div className="info__header">
          <h2 className="heading2">Automatic sizing for pipes and ducts</h2>

          <p className="text1">
            Used by MEP & HVAC engineers to do quick calculations of velocity
            and pressure drop
          </p>
        </div>
        <div className="info__steps">
          <div className="info__step">
            <Image className="info__image" filename={"icon1.png"} />
            <h3 className="text1">Select pipe and media</h3>
            <p className="text2">
              Select a pipe and media from our public database or add custom
              pipes for for your company
            </p>
          </div>
          <div className="info__step">
            <Image className="info__image" filename={"icon2.png"} />
            <h3 className="text1">Select flow and dimension criteria</h3>
            <p className="text2">
              Select a flow/power and maximum velocity and/or max pressure drop.
              All units can be easily toggled between
            </p>
          </div>
          <div className="info__step">
            <Image className="info__image" filename={"icon3.png"} />
            <h3 className="text1">Optimal size is displayed</h3>
            <p className="text2">
              5 sizes are displayed in a stack chart. The one in the middle is
              the size which meets the requirement
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}
export default Info
