import React from "react"
import Image from "../components/image"

const Comments = () => {
  return (
    <section id="comments" className="section comments">
      <div className="section__inner">
        <div className="comments__header">
          <h2 className="heading2">What our customers say</h2>
        </div>

        <div className="comments__comments">
          <div className="comments__comment">
            <p className="heading2 comment">
              My go to tool for sizing and calculating effects
            </p>
            <Image
              className="comments__comment--image"
              filename={"portrait1.jpeg"}
            />
            <p className="text1">Jacob Fingal</p>
            <p className="text2">Installation Manager, Skanska</p>
          </div>
          <div className="comments__comment">
            <p className="heading2 comment">
              Great tool, very intuitive and easy to use
            </p>
            <Image
              className="comments__comment--image"
              filename={"portrait2.jpeg"}
            />
            <p className="text1">Mattias Björn</p>
            <p className="text2">MEP Engineer, Energy Machines</p>
          </div>
        </div>
      </div>
    </section>
  )
}
export default Comments
