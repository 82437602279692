import React from "react"
import Image from "../components/image"

const LogoRow = () => {
  return (
    <section id="logos" className="section logos">
      <div className="section__inner">
        <div className="logos__header">
          <p className="text1 text-centered gray">
            Trusted by world leading engineering firms
          </p>
        </div>
        <div className="logos__wrapper">
          <div className="logo">
            <Image className="logo__image" filename={"logo_assemblin.png"} />
          </div>
          <div className="logo">
            <Image className="info__image" filename={"logo_multiconsult.png"} />
          </div>
          <div className="logo">
            <Image className="info__image" filename={"logo_skanska.png"} />
          </div>
          <div className="logo">
            <Image className="info__image" filename={"logo_sweco.png"} />
          </div>
          <div className="logo">
            <Image className="info__image" filename={"logo_wsp.png"} />
          </div>
        </div>
      </div>
    </section>
  )
}
export default LogoRow
