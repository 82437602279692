import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Info from "../components/info"
import Comments from "../components/comments"
import Pricing from "../components/pricing"
import Faq from "../components/faq"
import LogoRow from "../components/logo-row"

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <Hero />
      <LogoRow />
      <Info />
      <Comments />
      <Pricing />
      <Faq />
    </Layout>
  )
}

export default IndexPage
