import React from "react"
const Faq = () => {
  return (
    <section id="faq" className="section faq">
      <div className="section__inner">
        <div className="faq__header">
          <h2 className="heading2">Frequently asked questions</h2>
          <p className="text1">We have everything covered for you</p>
        </div>
        <div className="faq__q-wrap">
          <div className="faq__col">
            <p className="text1">
              What will I get with an Enterprise subscription?
            </p>
            <p className="text2">
              Your organization will get a shared pipe and media repository.
              Fully customizable to suit your organization needs. Also, each
              member of the account will get her own private pipe and media
              repository.
            </p>
            <p className="text1">Can I pay with credit card or PayPal?</p>
            <p className="text2">
              Not at this time. After you've placed an order you will receive an
              email with an invoice within 72 hours.
            </p>
          </div>
          <div className="faq__col">
            <p className="text1">What if I sign up and want to cancel?</p>
            <p className="text2">
              You can cancel your subscription at any time. Your subscription
              will remain active until the current time period is finished.
            </p>
            <p className="text1">Can I get a refund?</p>
            <p className="text2">
              If you're not satisfied, for any reason, within 30 days you get a
              full refund, period, no questions asked. We don't want your money
              if you're not amazingly happy.
            </p>
            <p className="text1">
              Contact us at info@sizepipe.com for more information.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}
export default Faq
