import React, { useContext } from "react"
//import Image from "../components/image"
import Laptop from "../svgs/laptop.svg"
import HeroVideo from "../videos/sp-small.mp4"
import {
  GlobalStateContext,
  GlobalDispatchContext,
} from "../context/globalState"

const Hero = () => {
  const state = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)

  const showOrderModal = (e) => {
    e.preventDefault()
    dispatch({ type: "SHOW_ORDER_MODAL" })
  }

  return (
    <section id="hero" className="section hero">
      <div className="section__inner">
        <div className="hero__text">
          <h1 className="heading1">
            Sleek, Intuitive and Powerful App for Pipe & Duct calculations
          </h1>
          <p className="text1">For HVAC & MEP Engineers</p>
          {state.isLoggedin ? (
            <a
              href="https://sizepipe.com/app/#/app/calculator"
              className="cta1"
            >
              Launch App!
            </a>
          ) : (
            <a href="https://sizepipe.com/app/#/signup" className="cta1">
              Get Started For Free
            </a>
          )}
        </div>
        <div className="hero__image">
          <div className="hero__image-inner-wrapper">
            <Laptop className="hero__image-background" />

            <div className="hero__image-front">
              <video loop autoPlay muted playsInline>
                <source src={HeroVideo} type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default Hero
