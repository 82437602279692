import React, { useContext } from "react"

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../context/globalState"

const Pricing = () => {
  const state = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)

  const showOrderModal = (e) => {
    e.preventDefault()
    dispatch({ type: "SHOW_ORDER_MODAL", payload: { plan: "organization" } })
  }

  return (
    <section id="pricing" className="section pricing">
      <div className="section__inner">
        <div className="pricing__header">
          <h2 className="heading2">Pricing</h2>
        </div>

        <div className="pricing__table">
          <div className="row">
            <div className="cell wide"></div>
            <div className="cell">
              <p className="text2">Single License</p>
            </div>
            <div className="cell">
              <p className="text2">Organization License</p>
            </div>
          </div>

          <div className="row">
            <div className="cell wide"></div>
            <div className="cell">
              <p className="text1">€200/year</p>
            </div>
            <div className="cell">
              <p className="text1">€650/year</p>
            </div>
          </div>

          <div className="row">
            <div className="cell wide">
              <p className="text2">Access to Pipe & Media Repository</p>
            </div>
            <div className="cell check"></div>
            <div className="cell check"></div>
          </div>

          <div className="row">
            <div className="cell wide">
              <p className="text2">Personal Pipe & Media Repository</p>
            </div>
            <div className="cell check"></div>
            <div className="cell check"></div>
          </div>

          <div className="row">
            <div className="cell wide">
              <p className="text2">Share Pipe & Media Repository</p>
            </div>
            <div className="cell"></div>
            <div className="cell check"></div>
          </div>
          <div className="row">
            <div className="cell wide">
              <p className="text2">Unlimited user for all employees</p>
            </div>
            <div className="cell"></div>
            <div className="cell check"></div>
          </div>

          <div className="row">
            <div className="cell wide no-mobile"></div>
            <div className="cell button">
              {state.isLoggedin ? (
                <a href="#" className="cta1" onClick={showOrderModal}>
                  Continue
                </a>
              ) : (
                <a href="https://sizepipe.com/app/#/signup" className="cta1">
                  Get Started For Free
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default Pricing
